import { markRaw } from 'vue'
import { states, type ModalStateType } from './states'

class AwesomeModal {
  open(component: object, opts: ModalStateType = {}) {
    Object.assign(states, {
      show: true,
      component: markRaw(component),
      empty: false,
      onClose: undefined,
      onSave: undefined,
      ...opts
    })
  }

  setEmpty(value: boolean) {
    states.empty = value
  }
}

export const showModal = new AwesomeModal()
