import { onMounted, ref, shallowRef } from 'vue'
import { defineStore } from 'pinia'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { local_storage_keys } from '@/utils/constants'
import moment from 'moment'
import { showModal } from '@/utils/awesome-modal'
import StaticPageContent from '@/components/shared/contents/StaticPageContent.vue'

type deviceType = 'mobile' | 'tablet' | 'desktop'

type messageModal = {
  title?: string
  description?: string
  component?: {
    file: object
    props?: object
    events?: object
  }
  autoClose?: boolean
  width?: number
}

export const useThemeStore = defineStore('theme', () => {
  const introScreen = ref<boolean>(true)
  const device = ref<deviceType>('desktop')
  const chatSideView = ref<boolean>(false)
  const emojiPicker = ref<boolean>(false)
  const mobileMenuActiveState = ref<'play' | 'fishers' | 'myBets'>('play')
  const messageModal = shallowRef<messageModal>({})
  const router = useRoute()
  const { locale, t } = useI18n()

  const setDevice = () => {
    if (window.innerWidth < 768) {
      device.value = 'mobile'
    } else if (window.innerWidth < 1025) {
      device.value = 'tablet'
    } else {
      device.value = 'desktop'
    }
  }

  const handleClickMain = (event: Event) => {
    const target = event.target as HTMLElement
    const closestChatBoxFooter: HTMLElement | null = target.closest('.chat-box-content__text-box')

    if (closestChatBoxFooter === null) {
      emojiPicker.value = false
    }
  }

  onMounted(() => {
    locale.value = router.query?.lang?.toString() ?? 'en'

    setDevice()

    window.addEventListener('resize', () => {
      setDevice()
    })

    setInterval(() => {
      const userTime = sessionStorage.getItem(local_storage_keys.USER_TIME)
      const hasUserSeen = sessionStorage.getItem(local_storage_keys.USER_SEEN_TIME_NOTIFY) === 'true'
      const isTimePassed = moment(userTime ?? '').isBefore()

      if (isTimePassed && !hasUserSeen) {
        messageModal.value = {
          title: t('general.modal.userTimeOver.title'),
          width: 600,
          description: t('general.modal.userTimeOver.description'),
          autoClose: false
        }
        showModal.open(StaticPageContent, {
          title: t('general.modal.userTimeOver.title'),
          icon: 'alarm-warning-line',
          componentProps: {
            description: t('general.modal.userTimeOver.description'),
          }
        })

        sessionStorage.setItem(local_storage_keys.USER_SEEN_TIME_NOTIFY, 'true')
      }
    }, 1000)
  })

  return {
    setDevice,
    device,
    chatSideView,
    locale,
    messageModal,
    emojiPicker,
    mobileMenuActiveState,
    handleClickMain,
    introScreen
  }
})
