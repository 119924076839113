import { reactive } from 'vue'

export type ModalStateType = {
  show?: boolean
  title?: string
  icon?: string
  empty?: boolean
  showCloseButton?: boolean
  onSave?: () => void
  onClose?: () => void
  onClickBack?: () => void
  component?: object
  componentProps?: any
  componentEvents?: any
}

export const states = reactive<ModalStateType>({
  show: false,
  title: '',
  icon: '',
  empty: false,
  showCloseButton: true,
  onSave: undefined,
  onClose: undefined,
  onClickBack: undefined,
  component: undefined,
  componentProps: undefined
})
